<template>

  <div>

    <v-sheet :color="wsATTENTION" height="200px"></v-sheet>

    <!--       News Content --><!--       News Content -->
    <div class="d-flex justify-center">
      <!-- Side Popup-->
      <v-sheet
          v-window-resize="handleResize"
          :width="windowWidth > 782 ?  '100%' : '0' "
          color="transparent"
      >
        <v-fade-transition>
          <v-sheet
              v-if="!closePopup && displayPopup"
              color="transparent"
              class="d-flex justify-end align-center "
              height="100vh"
              style="position:relative; z-index : 999 "
          >
            <v-sheet
                :style="`border : 3px solid  ${wsLIGHTACCENT};
                ${ windowWidth < 1180 ? ' bottom : 0  ; left : 0 ; right : 0 ; ' : ''}
                background: ${windowWidth > 1180 ? 'transparent' : 'white' };
                ${windowWidth > 1180 ? 'margin-bottom : 100px' : '' }  `"
                style="position: fixed; "
                class=" pa-4 "
                :width="windowWidth > 1180 ? '190' : '100%'"
                :class="windowWidth > 1180 ? 'wsRoundedHalf mr-3 ' : null"
            >
              <h4 :style="`color : ${wsACCENT}`" class="d-flex justify-space-between align-baseline" >
                {{ $t('homepage.tryForFree') }}
                <v-btn @click="closePopup = true" icon class="ml-5" >
                  <v-icon small :color="wsACCENT" > mdi-close</v-icon>
                </v-btn>

              </h4>
              <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular "
                  :class="windowWidth > 1180 ? 'mt-2' : null"
              >
                {{ $t('homepage.30DaysAccess') }}
              </h5>

              <v-row
                  :class="windowWidth < 1180 ? 'mt-4' : null "
                  no-gutters
              >
                <v-col
                    :cols=" windowWidth > 1180 ? '12' : '6' "
                    :class="[{'pr-1' : windowWidth < 1180 }]"
                >
                  <ws-button
                      :to="localeLink('register')"
                      :class="windowWidth > 1180 ? 'mt-8' : null"
                      label="Register"
                      block
                  />
                </v-col>
                <v-col
                    :cols=" windowWidth > 1180 ? '12' : '6' "
                    :class="[{'pl-1' : windowWidth < 1180 }]"
                >
                  <ws-button
                      :to="localeLink('')"
                      :class="windowWidth > 1180 ? 'mt-3' : null"
                      label="LearnMore"
                      block
                      outlined
                  />
                </v-col>



              </v-row>


            </v-sheet>

          </v-sheet>
        </v-fade-transition>

      </v-sheet>
      <!-- News Content -->
      <v-sheet
          color="transparent"
          class="d-flex justify-center"
          :width="windowWidth > 782 ?  '782' : '100%'  "

      >
        <v-sheet class="py-16 pt-6 px-6" color="transparent" max-width="782" :width="windowWidth > 782 ?  '782' : '100%' " >

          <!-- Breadcrumbs -->
          <ws-bread-crumbs :items="breadCrumbs" />
          <!-- Date, Reading time -->
          <h4 style="font-size: 16px; font-weight: 400"
              :style="`color : ${wsDARKLIGHT}`"
              class="mb-4 mt-10"
          >
            {{ PARSE_DATE(entity.date_published , false ,true).toLowerCase() }} · {{ $t('Read') }} {{ readTime }} {{ $t('min') }}
          </h4>
          <!-- Title -->
          <h1 style=" line-height: 1.2; "
              :style="`color : ${wsDARKER}; font-size: ${!SM ? 42 : 24}px; `"
              class="mb-4">
            {{ entity.title }}
          </h1>
          <!-- Socials -->
          <home-socials class="ml-n1" />
          <!-- Preview Image -->
          <v-img
              v-if="entity.img"
              :height="!SM ? '466' : '200'"
              style="border-radius: 8px"
              class="my-8"
              :src="entity.img"
              transition="xxx"
          />
          <!-- Subtitle -->
          <h2 id="subtitle"  style="line-height: 1.5; font-weight: 300; "
              :style="`color : ${wsACCENT};
                font-size: ${!SM ? 32 : 18 }px
                `"
          >
            {{ entity.subtitle }}
          </h2>
          <ws-text-viewer ref="text" class="mt-8 mr-16 nunito" :value="entity.content" />
          <h2 class="mb-5"> {{ $t('ReadMore') }}</h2>


          <!-- Recent news -->
          <ws-link
              v-for="(item , i) in recentNews" :key="i"
              :to="!item.external_link && !!item.alias  ? localeLink('news/' + item.alias) : null"
              :href="item.external_link || null"
              new-window
          >
            <v-sheet
                class="mb-8"
                color="transparent"
                :class="[{'d-flex' : !SM}]"
            >
              <v-sheet v-if="!SM && !item.img" dark max-width="273" width="273" height="161" :color="wsDARKLIGHT"
                       class="wsRoundedHalf d-flex align-center justify-center">
                <v-icon size="72">mdi-image</v-icon>
              </v-sheet>
              <v-img v-else-if="item.img" :width="!SM ? '273' : '100%'" :max-width="!SM ? '273' : '100%'" height="161" style="border-radius: 8px" :src="item.img" transition="xxx" />

              <div :class="[{'pl-8' : !SM}]">

                <h5
                    id="recent"
                    style="font-size: 13px"
                    :style="`color : ${wsDARKLIGHT}`"
                    :class="[{'mt-4' : SM}]"
                > {{ PARSE_DATE(item.date_published , false ,true) }} </h5>

                <h4 class="mt-4"
                    style="font-size: 18px; line-height: 27px; font-weight: 400"
                    :style="`color : ${wsDARKER}`">
                  {{ item.title }}
                </h4>

              </div>
            </v-sheet>
          </ws-link>



        </v-sheet>
      </v-sheet>
      <!-- Spacer -->
      <v-sheet color="transparent"  :width="windowWidth > 782 ?  '100%' : '0' "    />
    </div>



    <!-- Register -->
    <v-sheet
        v-intersect="intersector"
        class="d-flex justify-center py-10 px-6">
      <v-sheet >
        <h1 style="font-size: 34px; line-height: 48px"
            :style="`color : ${wsDARKER}; ${SM ? 'font-size : 19px; line-height : 1.5' : ''}`" >
          {{ $t('homepage.news.createCourses.title') }}
        </h1>
        <h4 style="font-size: 16px; line-height: 25px; font-weight: 400" :style="`color : ${wsDARKER}`" > {{ $t('homepage.news.createCourses.text') }}</h4>
        <div class="d-flex  mx-auto mt-6">
          <ws-text-field :width="!SM ? '375' : null" placeholder="company@name.com" />
          <v-btn
              :to="localeLink('register')"
              :color="wsATTENTION" height="40" elevation="0" dark class="noCaps ml-n1"> {{ $t('Register')}}</v-btn>
        </div>
      </v-sheet>

    </v-sheet>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import homeSocials from "@/components/pages/homepage_westudy/UI/homeSocials";
import wsBreadCrumbs from "@/components/pages/homepage/UI/wsBreadCrumbs";

export default {
  name: "SingleNews",
  props: ['alias'],
  components : {
    homeSocials,
    wsBreadCrumbs

  },
  data() {
    return {
      displayPopup : false,
      closePopup : false,
      readTime : 0,
      entity : {},
      recentNews : [],
      categoryNews : [],
      windowWidth : 2000,
    }
  },
  computed : {
    breadCrumbs() {
      return [
        { text : this.$t('Home') , route : this.localeLink('') },
        { text : this.$t('Blog') , route : this.localeLink('news') },
        { text : this.$t(this.entity.category_value)   }
      ]
    }
  },
  watch : {
    alias() {
      this.windowWidth = window.innerWidth
      this.getNews()
      this.displayPopup = true
    },
    LANG() {
      this.getNews()
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_NEWS_MAIN_ONE_PUBLIC', 'GET_NEWS_MAIN_PUBLIC']),

    handleResize() {
      this.windowWidth = window.innerWidth
    },

    intersector(entries) {
      if (entries[0]) {
        if (!entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          this.displayPopup = true
        }
        if (entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          this.displayPopup = false
        }
      }
    },

    prepareStyles() {
      let text = document.body.querySelectorAll('p')
      let h2 = document.body.querySelectorAll('h2')

      let allText = this.entity.subtitle + ' ' + this.entity.title
      this.readTime = Math.ceil(allText.split(/\s+/).length / 190);
      let content = this.$refs.text
      if ( content ) {
        let container = content.$el
        if ( container ) {
          if ( container.innerText ) {
            let allText = this.entity.subtitle + ' ' + this.entity.title + ' ' +  container.innerText.trim()
            this.readTime += Math.ceil(allText.split(/\s+/).length / 190);
          }
        }
      }
      text.forEach((el,index) => {
        el.style.fontSize = '18px'
        el.style.paddingBottom = '23px'
        el.style.color = this.wsDARKER


        if ( index === 0 ) {
          const firstLetter = el.textContent.charAt(0);
          el.innerHTML = `<div>${firstLetter}</div>${el.textContent.slice(1)}`;
          const firstLetterElement = el.querySelector('div');
          firstLetterElement.style.fontFamily = 'AmbulatoriaD'
          firstLetterElement.style.fontSize = '92px';
          firstLetterElement.style.lineHeight = '82px';
          firstLetterElement.style.fontWeight = '600';
          firstLetterElement.style.paddingRight = '15px';
          firstLetterElement.style.float = 'left';
        }
      })
      h2.forEach(el => {

        if (!el.id ) {
          el.style.fontSize = '24px'
          el.style.lineHeight = '36px'
          el.style.color = this.wsDARKER
          el.style.paddingBottom = '24px'
        }

      })



    },

    async getNews() {
      let result =  await this.GET_NEWS_MAIN_ONE_PUBLIC(this.alias)
      if ( !result ) {
        return
      }

      this.entity = result.news
      this.recentNews = result.recent

      setTimeout(() => {
        this.prepareStyles()
      }, 300)

    }
  },
  async mounted(){
    this.windowWidth = window.innerWidth
    await this.getNews()
    this.displayPopup = true
  }
}
</script>

<style lang="scss" scoped>

.main_section {
  width:1100px
}
.sideNews  {
  text-decoration: none;
  line-height: 1.5 ;
  font-size: 0.75rem;
  font-weight: 400;

  font-family: sans-serif;
  color: #393939;
}
</style>